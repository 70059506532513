ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.list-dash-small {
	margin-left: 16px;
	font-size: 1.3125rem;
	margin-bottom: 6px;
	line-height: 1.2;

	&:before {
		content: '-';
		vertical-align: baseline;
		color: $blue;
		font-size: 1.3rem;
		margin-left: -16px;
		padding-right: 10px;
	}
}

.list-dash {
	margin-left: 26px;
	font-size: 1.3125rem;
	margin-bottom: 13px;

	&:before {
		content: '-';
		vertical-align: sub;
		color: $blue;
		font-size: 2.5rem;
		margin-left: -26px;
		padding-right: 15px;
		line-height: 0;
		position: relative;
		top: -2px;	
	}
}