@include mq-add-breakpoint(mobile-xl, 480px);
@include mq-add-breakpoint(phablet, 600px);

.main {
    animation-delay: 1.75s;
    animation-duration: .5s;

    .background {
        background: $grey-bg;
    }

    .background-blue {
        background: $blue;

        h2,
        p {
            color: #FFF;
        }
    }
}

$ribbon-tri-width: 101px;
$ribbon-tri-height: 25px;
$ribbon-tri-padding: 4px;
$ribbon-background-color: $blue;
$ribbon-stroke-color: #fff;

.ribbon {
    background-color: $ribbon-background-color;
    color: $ribbon-stroke-color;
    display: inline-block;
    width: $ribbon-tri-width;
    position: relative;
    text-decoration: none;
    padding: 0 $ribbon-tri-padding calc($ribbon-tri-padding / 2);
    text-align: center;
    z-index: 4;

    animation: slidein 2s cubic-bezier(0.76, 0, 0.24, 1) forwards;

    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: $ribbon-background-color transparent transparent transparent;
        border-width: #{$ribbon-tri-height - calc($ribbon-tri-padding / 2) } #{calc($ribbon-tri-width / 2)} 0 #{calc($ribbon-tri-width / 2)};

        position: absolute;
        top: 100%;
        left: 0;

    }
}

.ribbon-inner {
    display: inline-block;
    border-left: 1px solid $ribbon-stroke-color;
    border-right: 1px solid $ribbon-stroke-color;
    position: relative;
    height: 100%;
    padding: 6px 29px 0px;


    &:before,
    &:after {
        content: '';
        display: block;

        //css triangle
        width: 0;
        height: 0;
        border-style: solid;
        border-color: $ribbon-background-color transparent transparent transparent;
        border-width: #{$ribbon-tri-height - $ribbon-tri-padding} #{(calc($ribbon-tri-width / 2)) - $ribbon-tri-padding} 0 #{(calc($ribbon-tri-width / 2)) - $ribbon-tri-padding};
        margin-top: -1px;

        position: absolute;
        top: 100%;
        left: -1px;
        z-index: 5;
    }


    &:before {
        border-color: $ribbon-stroke-color transparent transparent transparent;
        border-width: #{$ribbon-tri-height - $ribbon-tri-padding} #{(calc($ribbon-tri-width / 2)) - $ribbon-tri-padding} 0 #{(calc($ribbon-tri-width / 2)) - $ribbon-tri-padding};
        margin-top: 0;
    }
}

@-webkit-keyframes slidein {
    from {
        transform: translate(0, -125px);
    }

    to {
        transform: translate(0, 0);
    }
}

@-o-keyframes slidein {
    from {
        transform: translate(0, -125px);
    }

    to {
        transform: translate(0, 0);
    }
}

@-moz-keyframes slidein {
    from {
        transform: translate(0, -125px);
    }

    to {
        transform: translate(0, 0);
    }
}

@keyframes slidein {
    from {
        transform: translate(0, -125px);
    }

    to {
        transform: translate(0, 0);
    }
}