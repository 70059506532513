#trainmed-eucookie {
    display: none;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.9);
    padding: 10px;
    z-index: 9999;
    font-size: 13px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    p {
        margin: 0 20px;
        display: inline-block;
    }
    button {
        border: 0;
        outline: 0;
        color: #333;
        background-color: #DDD;
        transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out;
        border-radius: 5px;
        cursor: pointer;
        margin: 5px 5px 4px 33px;
        padding: 7px 33px 7px 33px;
        &:hover,
        &:active,
        &:focus {
            outline: 0;
            background-color: #CCC;
        }
    }
    a:link,
    a:visited {
        white-space: nowrap;
        text-decoration: none;
        color: #333;
        transition: 200ms color;
        text-decoration: underline;
    }
    a:hover,
    a:active,
    a:focus {
        text-decoration: none;
    }
}
