.header {
	position: relative;
	padding-top: 110px;
	margin-bottom: 50px;
	lost-center: $wrapper-max-width $wrapper-padding;

	.PageTitle {

		.PageTitle-logoImg {
			background:transparent url('../img/logo-trainmed.png') left top no-repeat;
			background-size: 100%;
			width: 120px;
			height: 145px;
			margin-left: 115px;
		}

		h1.PageTitle-headline {
			color: $grey-page-title-headline;
			line-height: 1.3;
			margin-top: 0;

			animation-delay: 1s;
			animation-duration: 1s;

			.PageTitle-logoText {
				font-weight: 300;
				font-size: 2.8125rem;

				span { 
					font-weight: 400;
				}
			}

			.PageTitle-maskText {
				font-weight: 300;
			}
		}

		h2.PageTitle-text {
			color: $grey-page-title-text;
			font-weight: 300;
			font-size: 1.875rem;
			line-height: 1.2;
			margin-top: 2.5rem;
			margin-bottom: .9375rem;

			animation-delay: 1s;
			animation-duration: 1s;
		}
	}
	
}
/* .logo {
  margin: -58px 0 0 -100px;
  @include position(absolute, 50% 0 0 50%);
  img {

  }
} */
