.footer {
	background:$blue;
	color: #FFF;
	text-align: center;
	padding-top: 15px;
	padding-bottom: 15px;

	animation-delay: 1.75s;
	animation-duration: .5s;

	div {
		lost-center: $wrapper-max-width $wrapper-padding;

		&.ContactEmail {
			a { 
				display: block;
				font-size: 1.3125rem;
				font-weight: 300;
				letter-spacing: .25rem; 
				padding: 8px 0 10px;
			}
		}
		&.legal {
			font-size: .875rem;
			letter-spacing: .12em;
			a { 
				font-size: .875rem;
				font-weight: 300;
				letter-spacing: 0; 

			}
		}
	}
	a:link, a:visited {
		color: #FFF;
		text-decoration: none;
	}
}
