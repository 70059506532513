* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  /* font-size: 62.5%; */
  /* background: radial-gradient(ellipse at center, $base 0, $darken 80%, $back 100%); */
}
body {
  font: 300 16px/1.6 Roboto, Calibri, Gill Sans, Myriad Pro, Myriad,
    Helvetica Neue, Helvetica, Arial, sans-serif;
  color: darken($grey-page-title-headline, 10%);
  transition: all 0.4s 0.3s ease-in;
  font-weight: 300;
}
body,
html {
  /* min-height: 100vh;
  overflow: hidden; */
}

h2,
.h2 {
  font-size: 1.875rem;
  font-weight: 300;
}
h3,
.h3 {
  font-size: 1.3125rem;
  font-weight: 300;
}

p {
  font-size: 1rem;
}
b,
strong {
  font-weight: 500;
}

h2 {
  span {
    font-weight: 400;
  }
}

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: "Material Icons";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.mgb30 {
  margin-bottom: 30px;
}
.mgt40 {
  margin-top: 40px;
}
.mgt0 {
  margin-top: 0;
}

.accordionHeaderClosed {
  color: #444;
  text-shadow: 1px 1px 1px #fff;
  cursor: pointer;
}

.accordionHeaderClosed:after {
  content: "\f107";
  font-family: FontAwesome;
  padding: 0 12px;
}

.accordionHeaderClosed:hover,
.accordionHeaderClosed:focus {
  color: $blue;
  text-decoration: none;
}

.accordionHeaderOpen {
  color: $blue;
}

.accordionHeaderOpen:after {
  content: "\f106";
  font-family: FontAwesome;
  padding: 0 12px;
}

.accordion {
  width: 100%;
}

h2,
.text-link {
  a:link,
  a:visited {
    text-decoration: none;
    color: $grey-page-title-text;
    border-bottom: 1px solid lighten($grey-page-title-text, 10%);
    transition: all 0.3s ease-in-out;
  }
  a:hover,
  a:focus,
  a:active {
    border-bottom: 1px solid $blue;
    color: $blue;
  }
}
a:link,
a:visited {
  &.text-link {
    text-decoration: none;
    color: $grey-page-title-headline;
    border-bottom: 1px solid lighten($grey-page-title-text, 10%);
    transition: all 0.3s ease-in-out;
  }
}
a:hover,
a:focus,
a:active {
  &.text-link {
    border-bottom: 1px solid $blue;
    color: $blue;
  }
}

p.cc_message {
  font-size: 15px !important;
}
