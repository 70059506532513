.main {
	.tabs {
		position: relative;
		overflow: hidden;
		margin: 0 auto;
		width: 100%;
		font-weight: 300;
		font-size: 1.25em;

		nav {
			background:$grey-bg;

			@media screen and (max-width: 51.875em) {
				background: none;
			}
		}

		nav ul {
			position: relative;
			margin: 0 auto;
			padding: 0;
			list-style: none;
			
			lost-center: $wrapper-max-width 5px flex;

			@media screen and (max-width: 51.875em) {
				display: block;
				box-shadow: none;
			}

			li {
				position: relative;
				z-index: 1;
				display: block;
				margin: 0 0.5em;
				text-align: center;

				@media screen and (max-width: 51.875em) {
					display: block;
					background:$grey-bg;
					margin-bottom: 5px;
				}
				
				a {
					position: relative;
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					text-decoration: none;
					text-transform: uppercase;
					white-space: nowrap;
					line-height: 2.5;

					padding: 0 1.5em;
					color: #74777b;
					font-size: 1.125em;
					font-weight: 300;
					letter-spacing: .1em;
					transition: color 0.3s;

					&:before {
						@media screen and (max-width: 51.875em) {
							margin-right: 0;
						}
					}

					&:hover, &:focus, &:active {
						color: $blue;
						outline: none;

						&:after {
							background: $blue;
						}
					}

					span {
						vertical-align: middle;
						font-size: 0.75em;
					}

					&:after {
						position: absolute;
						top: 0;
						left: 0;
						z-index: -1;
						width: 100%;
						height: 100%;
						background: $grey-bg;
						content: '';
						transition: background-color 0.3s, transform 0.3s;
						transition-timing-function: ease, cubic-bezier(0.7,0,0.3,1);
						transform: translate3d(0,100%,0) translate3d(0,-3px,0);
					}
				}

				&.tab-current a {
					color: #fff;

					&:after {
						background: $blue;
						transform: translate3d(0,0,0);
					}
				}
			}
		}

		.content-wrap {
			lost-center: $wrapper-max-width $wrapper-padding flex;
			position: relative;

			section {
				display: none;
				margin: 0;
				padding: 1em 0;
				width: 100%;
				
				&.content-current {
					display: block;
				}
				h2 {
					span {
						font-weight: 400;
					}
				}

				article {
					lost-utility: clearfix;
					margin-bottom: 40px;

					&.pdf-formular {
						p {
							span {
								font-weight: 400;
							}
						}
					}

					&.teamTrainMed {
						@include mq($from: tablet) {
							display: flex;
						}

						.teamDateHeader {
							padding: 15px 0 10px;
							border-bottom: 1px solid $grey-bg;
							lost-column: 1/1 0;

							@include mq($from: tablet) {
								lost-column: 1/3 0;
								border-bottom: none;
								border-right: 1px solid $grey-bg;								
								margin-bottom: 0;
								padding-right: 30px; 
							}
						}
						.teamDateContent {
							lost-column: 1/1 0;

							@include mq($from: mobile-xl) {
								display: flex;
							}

							@include mq($from: tablet) {
								lost-column: 2/3 0;
							}							

							.team-date {
								lost-column: 1/1 0;								
								position: relative;
								margin-top: 25px;

								@include mq($from: mobile-xl) {
									lost-column: 1/2 0;
								}

								@include mq($from: tablet) {
									margin-top: 0;
									padding: 15px 0 20px;
								}	

								h3 {
									font-size: 1.1rem;
									font-weight: 300;
									margin-bottom: 10px;
									margin-top: 0;
								}
								p {
									font-size: .875rem;
									margin-bottom: 0;
									margin-top: 5px;

									&.phoneContact {
										margin-bottom: 15px;
										@include mq($from: mobile-xl) {
											margin-bottom: 60px;
										}
										@include mq($from: tablet) {
											margin-bottom: 40px;
										}
										i { 
											font-style: normal;
											display: inline-block;
											width: 50px;
										}
									}
									&.emailContact {
										@include mq($from: mobile-xl) {
											position: absolute;
											bottom: 20px;
										}
										&:before {
											@extend .material-icons;
											content: '\e0be';
											color: $grey-nav;												
											font-size: 1rem;
											vertical-align: sub;
											margin-right: 5px;
										}
									}
								}
								a:link, 
								a:visited {
									text-decoration: none;
									&.phoneLink {
										color: #444;
									}

									&.emailLink {
										border-bottom: 1px solid lighten($grey-page-title-text, 10%);
										color: #444;
									}
								}
								a:hover,
								a:focus,
								a:active {
									&.emailLink {
										border-bottom: 1px solid $blue;
										color: $blue;
									}
								}
								ul.qualifications {
									margin-bottom: 15px;
									@include mq($from: mobile-xl) {
										margin-bottom: 70px;
									}
									@include mq($from: tablet) {
										margin-bottom: 50px;
									}

									li {
										font-size: .875rem;
										width: 80%;

											&.categoryTwo {
												margin-top: 20px;
											}
									}
								}
							}
						}

					}

					h3 {
						margin: 0;
						font-size: 1.1rem;
						font-weight: 400;
					}

				}

			}
		}

		/* Fallback */
		.no-js .content-wrap section {
			display: block;
			padding-bottom: 2em;
			border-bottom: 1px solid rgba(255,255,255,0.6);
		}

		.no-flexbox nav ul {
			display: block;
		}

		.no-flexbox nav ul li {
			min-width: 15%;
			display: inline-block;
		}
	}
}

/* -------------------------- */
/* Anmeldung */
/* -------------------------- */
#notfallschulung-form { 
	float: left; 
	width: 100%; 
	font-size: 1rem;

	@include mq($from: desktop) {
		width: 100%;
	}

	#order-form > div {
		lost-utility: clearfix;
		background: lighten($grey-bg, 5%);
		margin-bottom: 25px;
		padding: 10px;

		&.contactInformations {
			margin-bottom: 10px;
		}

		&:nth-last-of-type(-n+3) {
			background:none;
		}
	}

	.row { 
				
		.col-2 {
			lost-utility: clearfix;
			padding: 7px;
			width: 100%; 
						
			@include mq(850px) {
				float: right;
				width: 47%;
				margin-right: 0;

				&:first-child { 
					float: left;
					margin-right:0;
				}
			}

			&.colspan-2 {
				width: 100%;

				.label {
					@include mq($from: 850px) { 
						width: 23.1%;
					}
				}
				.answer {
					@include mq($from: 850px) { 
						width: 76.9%;
					}
				}
			}
		}

		.label { 
			font-size: .9rem;
			width: 100%;			

			@include mq($from: phablet) { 
				float: left;

				width: 40%;
			}
			@include mq(850px) { 
				width: 50%;
			}
		}

		.answer { 
			width: 100%;

			@include mq($from: phablet) { 
				float: left;
				width: 60%;
			}
			@include mq($from: 850px) { 
				width: 50%;
			}

			> div {
				margin-bottom: 10px;
			}
		}
	}
}




.send { float: right; text-align: right; width: 100%; }
.antispam { display:none; }
.recipient {
	color: #868686;
    font-size: 1.25em;
    font-weight: 300;
    margin-bottom: 70px;
    span {
    	font-weight: 400;
    }
}
textarea { 
	width: 100%; 
	height: 100px; 
	border: 1px solid #CCC;
	border-radius: 0; 
	font-size: 0.875rem;  
	padding: 8px;
	outline: none;
	outline: 0;
	transition: .3s ease-in-out;
}
input[type=text], 
input[type=date], 
input[type=tel], 
input[type=email] { 
	border: 1px solid #CCC;
	border-radius: 0;  
	font-size: 0.875rem; 
	height: 30px; 
	padding: 0 8px 0; 
	width: 100%; 
	outline: none;
	outline: 0;
	transition: .3s ease-in-out;
}
input[type=text]:focus, 
input[type=date]:focus, 
input[type=tel]:focus, 
input[type=email]:focus,
textarea:focus { 
	border: 1px solid $blue; 
}
input[type=checkbox], 
input[type=radio] { display: none; }

input[type=checkbox] + label {
	content:'';
	background: url(../img/form/check01.png) 0 0 no-repeat; 
	background-size: 20px 20px;
	cursor: pointer;
	display: block;
	overflow: hidden;
	padding:0 0 0 36px;
	box-sizing: content-box;
	font-size: .875em;
}

input[type=checkbox]:checked + label { 
	background: url(../img/form/check02.png) no-repeat 0 0;
	background-size: 20px 20px;
	cursor: pointer;
	display: block;
	overflow: hidden;
	padding:0 0 0 36px;
}

input[type=radio] + label {
	padding-left: 36px;
	background: url(../img/form/radio01.png) no-repeat 0 0;
	background-size: 20px 20px;
	padding-top: 1px;
	overflow: hidden;
	cursor: pointer;
}

input[type=radio]:checked + label {
	padding-left: 36px;
	background: url(../img/form/radio02.png) no-repeat 0 0;
	background-size: 20px 20px;
	padding-top: 1px;
	overflow: hidden;
	cursor: pointer;
}

input[type=text].error, 
input[type=date].error, 
input[type=tel].error, 
input[type=email].error { 
	border-color: #e62220; 
	outline: none;
	outline: 0;  
	color: #000; 
}
.error { color: #e62220; font-size: .75rem; }
label.error { display: block; color: #e62220; } 
.contactErrorMessage {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    font-size: .875rem;
    display: none;
}
label[for=disclaimer] { cursor: pointer; }

label.error[for="disclaimer"] {
    margin-left: 35px;
    margin-top: 10px;
}