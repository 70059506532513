.main {
	.impressum{
		lost-center: $wrapper-max-width $wrapper-padding;
		margin-bottom: 50px;

		a:link, 
		a:visited {
			text-decoration: none;
			&.email {
				border-bottom: 1px solid lighten($grey-page-title-text, 10%);
				color: #444;
			}
		}
		a:hover,
		a:focus,
		a:active {
			&.email {
				border-bottom: 1px solid $blue;
				color: $blue;
			}
		}
	}
	.datenschutz{
		lost-center: $wrapper-max-width $wrapper-padding;
		margin-bottom: 50px;

		a:link, 
		a:visited {
			text-decoration: none;
			border-bottom: 1px solid lighten($grey-page-title-text, 10%);
			color: #444;
		}
		a:hover,
		a:focus,
		a:active {
			border-bottom: 1px solid $blue;
			color: $blue;
		}
	}
}