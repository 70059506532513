::selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-moz-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-webkit-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}

.fancybox-lock .fancybox-overlay {
	background: rgba(0,0,0,.9);
	cursor: pointer;
}
.fancybox-skin {
	background: none;
	border-radius: 0;
}
.fancybox-opened .fancybox-skin {
	box-shadow: none;
}
.fancybox-opened {
	cursor: default;
}
.fancybox-close {
	position: fixed;
	top: 20px;
	right: 40px;
	background: url(../img/fancybox/icon-close3.svg);
	transition: all .3s ease-in-out .001s;
	-webkit-transition: all .3s ease-in-out .001s;
	-moz-transition: all .3s ease-in-out .001s;
	-o-transition: all .3s ease-in-out .001s;

}

.fancybox-close:hover {
	-moz-transform: rotate(-90deg); 
	-webkit-transform: rotate(-90deg); 
	-o-transform: rotate(-90deg); 
	-ms-transform: rotate(-90deg); 
	transform: rotate(-90deg);
}

.fancybox-opened .fancybox-title {
	visibility: hidden;
}
.fancybox-nav span {
    width: 36px;
    height: 54px;
}
.fancybox-next span {
    right: 10px;
    background-position: 0 -100px;
}
.fancybox-prev span {
    left: 10px;
    background-position: 0 -50px;
}