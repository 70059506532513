.main {
    .web-seminare {
        @extend .clear;
        margin-bottom: 50px;

        .grid {

            lost-masonry-wrap: no-flex 1px;

            position: relative;
            margin: 0 auto;
            list-style: none;
            text-align: center;
            max-width: $wrapper-max-width;

        }



        article {
            padding: 1.5em;

            lost-masonry-column: 1/1;

            @include mq($from: phablet) {
                lost-masonry-column: 1/2 1px;
            }

            @include mq($from: desktop) {
                lost-masonry-column: 1/3 1px;
            }

            svg {
                max-width: 50px;
            }

            figure {
                max-width: 100%;

                img {
                    max-width: 100%;
                }
            }
        }

        .web-seminare--fact {
            position: relative;
        }

        .video-link {
            figure {
                position: relative;
                overflow: hidden;

                figcaption {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 98%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0;
                    transition: opacity 0.35s ease-in-out;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(to bottom, rgba($blue, 0) 0%, rgba($blue, 0.8) 75%);
                        content: '';
                        opacity: 0;
                        transition: opacity 0.35s ease-in-out;
                        z-index: 1;
                    }

                    >div {
                        width: calc(100% - 32px);
                        margin: 1em;
                        color: #FFF;
                        position: relative;
                        z-index: 2;

                        a:link,
                        a:visited {
                            background: rgba(255, 255, 255, .3);
                            border: 1px solid #FFF;
                            padding: 4px 2px;
                            display: block;
                            font-weight: 500;
                            color: #FFF;
                            margin-top: .875em;
                            text-decoration: none;
                            transition: background .3s ease-in-out, color .3s ease-in-out;
                        }
                        a:hover,
                        a:active,
                        a:focus {
                            background: rgba(255, 255, 255, 1);
                            color: $blue;
                        }
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        opacity: 1;

                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        // .video-link {
        //     background: linear-gradient(180deg,rgba(35,73,163,0) 0,rgba(35,73,163,.8) 75%);
        // }
    }

    .profil-contact.web-seminare-contact {
        lost-center: 59.125rem $wrapper-padding flex;
        text-align: center;

        @include mq($from: tablet) {
            text-align: left;
        }
    }

    .interesse {
        margin-bottom: 50px;
        padding: 48px 0;

        lost-utility: clearfix;

        header {
            lost-center: $wrapper-max-width $wrapper-padding flex;

            h2 {
                margin: 0;
                text-align: center;
                width: 100%;
            }
        }

        article {
            lost-center: $wrapper-max-width $wrapper-padding;
            text-align: center;
            margin: 0 auto;

            p {
                text-align: center;
                margin: 10px auto 0;

                @include mq($from: tablet) {
                    max-width: 100%;
                }
            }
        }

        .anmeldungButtons {
            lost-center: $wrapper-max-width $wrapper-padding flex;
            justify-content: center;
            margin-top: 40px;
        }

    }
}