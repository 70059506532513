$base : #F7F6F0;
$darken : darken(#F7F6F0 , 30%);
$back: darken(#F7F6F0, 40%);

$blue:  #2349a3;
$grey-nav: #a4a4a4;
$grey-bg: #ececec;
$grey-page-title-headline: #868686;
$grey-page-title-text: darken(#b5b5b5, 5%);

$wrapper-max-width: 73.125rem;
$wrapper-max-width-big: 100rem;
$wrapper-padding: 15px;