.main {
    .angebote {
        @extend .clear;
        margin-bottom: 50px;

        .grid {

            lost-masonry-wrap: no-flex 1px;

            position: relative;
            margin: 0 auto;
            list-style: none;
            text-align: center;
            max-width: $wrapper-max-width;
            padding: 0 $wrapper-padding;

            figure {
                background: #ccc;
                height: 250px;
                margin-bottom: 1px;

                position: relative;
                float: left;
                overflow: hidden;

                lost-masonry-column: 1/1;

                @include mq($from: mobile-xl) {
                    lost-masonry-column: 1/2 1px;
                }

                @include mq($from: tablet) {
                    lost-masonry-column: 1/3 1px;
                }

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    opacity: 1;
                }

                &:before {
                    z-index: 1;
                    transition: opacity .35s ease-in-out;
                }

                &:nth-child(1) {

                    &:before {
                        background: transparent url('../img/angebote/strukturierte-patientenversorgung/strukturierte-patientenversorgung-active.jpg') center center no-repeat;
                        background-size: cover;
                    }

                    &:after {
                        background: transparent url('../img/angebote/strukturierte-patientenversorgung/strukturierte-patientenversorgung-start-active.jpg') center center no-repeat;
                        background-size: cover;
                    }
                }

                &:nth-child(2) {
                    &:before {
                        background: transparent url('../img/angebote/optimierung-kardiopulmonale-reanimation/optimierung-kardiopulmonale-reanimation-active.jpg') center center no-repeat;
                        background-size: cover;
                    }

                    &:after {
                        background: transparent url('../img/angebote/optimierung-kardiopulmonale-reanimation/optimierung-kardiopulmonale-reanimation-start-active.jpg') center center no-repeat;
                        background-size: cover;
                    }
                }

                &:nth-child(3) {
                    &:before {
                        background: transparent url('../img/angebote/defibrillation/defibrillation-active.jpg') center center no-repeat;
                        background-size: cover;
                    }

                    &:after {
                        background: transparent url('../img/angebote/defibrillation/defibrillation-start-active.jpg') center center no-repeat;
                        background-size: cover;
                    }
                }

                &:nth-child(4) {
                    &:before {
                        background: transparent url('../img/angebote/abtragen-der-hypoxiestrecke/abtragen-der-hypoxiestrecke-active.jpg') center center no-repeat;
                        background-size: cover;
                    }

                    &:after {
                        background: transparent url('../img/angebote/abtragen-der-hypoxiestrecke/abtragen-der-hypoxiestrecke-start-active.jpg') center center no-repeat;
                        background-size: cover;
                    }
                }

                &:nth-child(5) {
                    &:before {
                        background: transparent url('../img/angebote/therapie-anaphylaktischer-reaktionen/therapie-anaphylaktischer-reaktionen-active.jpg') center center no-repeat;
                        background-size: cover;
                    }

                    &:after {
                        background: transparent url('../img/angebote/therapie-anaphylaktischer-reaktionen/therapie-anaphylaktischer-reaktionen-start-active.jpg') center center no-repeat;
                        background-size: cover;
                    }
                }

                &:nth-child(6) {
                    &:before {
                        background: transparent url('../img/angebote/luftwegemanagement-spezielle-angebote/luftwegemanagement-spezielle-angebote-active.jpg') center center no-repeat;
                        background-size: cover;
                    }

                    &:after {
                        background: transparent url('../img/angebote/luftwegemanagement-spezielle-angebote/luftwegemanagement-spezielle-angebote-start-active.jpg') center center no-repeat;
                        background-size: cover;
                    }
                }

                &:nth-child(1).colio-active-item {
                    &:before {
                        background: transparent url('../img/angebote/strukturierte-patientenversorgung/strukturierte-patientenversorgung-start-active.jpg') center center no-repeat;
                        background-size: cover;
                    }
                }

                &:nth-child(2).colio-active-item {
                    background: transparent url('../img/angebote/optimierung-kardiopulmonale-reanimation/optimierung-kardiopulmonale-reanimation-start-active.jpg') center center no-repeat;
                    background-size: cover;
                }

                &:nth-child(3).colio-active-item {
                    background: transparent url('../img/angebote/defibrillation/defibrillation-start-active.jpg') center center no-repeat;
                    background-size: cover;
                }

                &:nth-child(4).colio-active-item {
                    background: transparent url('../img/angebote/abtragen-der-hypoxiestrecke/abtragen-der-hypoxiestrecke-start-active.jpg') center center no-repeat;
                    background-size: cover;
                }

                &:nth-child(5).colio-active-item {
                    background: transparent url('../img/angebote/therapie-anaphylaktischer-reaktionen/therapie-anaphylaktischer-reaktionen-start-active.jpg') center center no-repeat;
                    background-size: cover;
                }

                &:nth-child(6).colio-active-item {
                    background: transparent url('../img/angebote/luftwegemanagement-spezielle-angebote/luftwegemanagement-spezielle-angebote-start-active.jpg') center center no-repeat;
                    background-size: cover;
                }

                figcaption {
                    color: #FFF;
                    backface-visibility: hidden;
                    position: relative;
                    z-index: 3;

                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: flex-end;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(to bottom, rgba($blue, 0) 0%, rgba($blue, 0.8) 75%);
                        content: '';
                        opacity: 0;
                        transform: translate3d(0, 50%, 0);
                        transition: opacity 0.35s, transform 0.35s;
                    }

                    >a {
                        z-index: 1000;
                        text-indent: 200%;
                        white-space: nowrap;
                        font-size: 0;
                        opacity: 0;

                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    h2 {
                        position: absolute;
                        top: 27%;
                        left: 0;
                        width: calc(100% - 20%);
                        background-color: rgba(255, 255, 255, .8);
                        border: 1px solid #FFF;
                        font-size: 1rem;
                        font-weight: 300;
                        transition: transform 0.35s, color 0.35s;
                        transform: translate3d(0, -50%, 0);
                        margin: 0 10%;
                        padding: .03em .25em;
                        color: #333;
                        min-height: 3.5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    p {
                        font-size: 1.25em;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 2em;
                        width: 100%;
                        opacity: 0;
                        transform: translate3d(0, 10px, 0);
                        transition: opacity 0.35s, transform 0.35s;

                        >span {
                            text-transform: uppercase;
                            font-weight: 400;
                            font-size: .75em;
                            letter-spacing: 1px;
                            text-decoration: underline;
                        }
                    }
                }

                &:hover,
                &.colio-active-item {
                    &:before {
                        opacity: 0;
                    }

                    p {
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                        transition-delay: 0.3s;
                    }

                    figcaption {
                        &:before {
                            opacity: 1;
                            transform: translate3d(0, 0, 0);
                        }

                        h2 {
                            opacity: 1;
                            transform: translate3d(0, 10%, 0);
                        }
                    }
                }

                &.colio-active-item {
                    p {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .anmeldung {
        margin-bottom: 50px;
        lost-utility: clearfix;

        header {
            lost-center: $wrapper-max-width $wrapper-padding flex;

            h2 {
                margin: 0;
                text-align: center;
                width: 100%;
            }
        }

        article {
            lost-center: $wrapper-max-width $wrapper-padding flex;
            text-align: center;
            margin: 0 auto;

            p {
                text-align: center;
                margin: 10px auto 0;

                @include mq($from: tablet) {
                    max-width: 100%;
                }
            }

            .anmeldungButtons {
                lost-center: $wrapper-max-width $wrapper-padding flex;
                margin-top: 40px;

                >div {
                    lost-column: 1/1;
                    margin-bottom: 10px;

                    @include mq($from: mobile-xl) {
                        lost-column: 1/2;
                    }
                }
            }
        }
    }
}

.colio {
    lost-center: $wrapper-max-width $wrapper-padding flex;
    /* max-width: $wrapper-max-width !important;
	padding: 0 $wrapper-padding !important;
	margin: 0 auto; */
}

.colio-placement-inside,
.colio-placement-before {
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    width: 100%;
    max-width: $wrapper-max-width !important;
    padding: 0 $wrapper-padding !important;
}

.colio-placement-after {
    margin-top: 0;
    margin-bottom: 0;
}

.colio-theme-black {
    /* margin-right: 0!important; */

    &.colio-expanded {
        /* width: 100%!important; */
    }

    .colio-close {
        top: 40px !important;
        right: 10px !important;
    }

    .colio-navigation {
        top: 40px !important;
        left: 37px;

        a {
            background: $blue;
        }
    }

    .colio-container {
        background: none !important;
        padding: 100px 20px 40px !important;
        width: 100% !important;

        @include mq($from: tablet) {
            padding: 75px 0 40px !important;
        }


        h3 {
            margin: 0 !important;
            color: darken($grey-page-title-headline, 10%);
        }

        p {
            color: darken($grey-page-title-text, 20%);
        }
    }

    .main {
        animation-delay: 0s;
        width: 51%;
    }

    .side {
        float: right;
        width: 48%;

        .feed {

            li {
                background: $blue url('../img/expand/plus.png') no-repeat center;

                a {
                    img {
                        &.portrait {
                            height: 200px;
                            width: auto;
                        }

                        &.rean1 {
                            position: relative;
                            left: -50px;
                        }

                        &.rean2 {
                            position: relative;
                            left: -17px;
                        }

                        &.rean3 {
                            position: relative;
                            left: -60px;
                        }

                        &.rean4 {
                            position: relative;
                            left: -44px;
                        }

                        &.lwm {
                            position: relative;
                            left: -50px;
                        }

                        &.lwm2 {
                            position: relative;
                            left: -40px;
                        }

                        &.ana {
                            position: relative;
                            left: -30px;
                        }
                    }
                }
            }
        }
    }

}