.main {
    .home {
        margin-bottom: 50px;

        .grid {
            lost-center: $wrapper-max-width-big $wrapper-padding flex;

            position: relative;
            margin: 0 auto;
            list-style: none;
            text-align: center;

            .effectGrid {
                background: #ccc;
                height: 480px;
                margin-bottom: 10px;

                position: relative;
                float: left;
                overflow: hidden;
                transition: all 1s ease-in-out;

                lost-column: 1/1;

                @include mq($from: phablet) {
                    lost-column: 1/2 2 1px;
                    margin-bottom: 1px;
                }

                @include mq($from: desktop) {
                    lost-column: 1/4 4 1px;
                    margin-bottom: 0;
                }

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    opacity: 1;
                }

                &:before {
                    z-index: 1;
                    transition: opacity .35s ease-in-out;
                }

                &:after {
                    z-index: 0;
                }

                &:nth-child(1) {
                    &:before {
                        background: url('../img/home/TrainMed-Profil_sepia.jpg') left top no-repeat;
                        background-size: cover;
                    }

                    &:after {
                        background: url('../img/home/TrainMed-Profil_farbig.jpg') left top no-repeat;
                        background-size: cover;
                    }
                }

                &:nth-child(2) {
                    &:before {
                        background: transparent url('../img/home/TrainMed-Angebot_sepia.jpg') left top no-repeat;
                        background-size: cover;
                    }

                    &:after {
                        background: transparent url('../img/home/TrainMed-Angebot_farbig.jpg') left top no-repeat;
                        background-size: cover;
                    }
                }

                &:nth-child(3) {
                    &:before {
                        background: transparent url('../img/home/TrainMed-Web-Seminar_sepia.jpg') left top no-repeat;
                        background-size: cover;
                    }

                    &:after {
                        background: transparent url('../img/home/TrainMed-Web-Seminar_farbig.jpg') left top no-repeat;
                        background-size: cover;
                    }
                }

                &:nth-child(4) {
                    &:before {
                        background: transparent url('../img/home/TrainMed-Service_sepia.jpg') left top no-repeat;
                        background-size: cover;
                    }

                    &:after {
                        background: transparent url('../img/home/TrainMed-Service_farbig.jpg') left top no-repeat;
                        background-size: cover;
                    }
                }

                img {
                    display: none;
                }

                figcaption {
                    padding: 2em;
                    color: #FFF;
                    backface-visibility: hidden;

                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 3;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(to bottom, rgba($blue, 0) 0%, rgba($blue, 0.8) 75%);
                        content: '';
                        opacity: 0;
                        transform: translate3d(0, 50%, 0);
                        transition: opacity 0.35s, transform 0.35s;
                    }

                    >a {
                        z-index: 1000;
                        text-indent: 200%;
                        white-space: nowrap;
                        font-size: 0;
                        opacity: 0;

                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    // h2 {
                    //     background-color: rgba(255, 255, 255, 0.6);
                    //     border: 1px solid #FFF;
                    //     font-size: 1.5rem;
                    //     font-weight: 300;
                    //     margin-top: 26%;
                    //     transition: transform 0.35s, background 0.1s;
                    //     transform: translate3d(0, -30px, 0);
                    // }

                    // p {
                    //     padding: 0.5em 2em;
                    //     text-transform: none;
                    //     opacity: 0;
                    //     transform: translate3d(0, -10px, 0);
                    //     transition: opacity 0.35s, transform 0.35s;
                    // }

                    h2 {
                        position: absolute;
                        top: 27%;
                        left: 0;
                        width: calc(100% - 20%);
                        background-color: rgba(255, 255, 255, .6);
                        border: 1px solid #FFF;
                        font-size: 1.5rem;
                        font-weight: 300;
                        transition: transform 0.35s, color 0.35s;
                        transform: translate3d(0, -50%, 0);
                        margin: 0 10%;
                        padding: .5em;
                        color: #333;
                    }

                    p {
                    	font-size: 1.25em;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 2em;
                        width: 100%;
                        opacity: 0;
                        transform: translate3d(0, 10px, 0);
                        transition: opacity 0.35s, transform 0.35s;

                        > span {
                        	text-transform: uppercase;
                        	font-weight: 400;
                        	font-size: .75em;
                        	letter-spacing: 1px;
                        	text-decoration: underline;
                        }
                    }
                }

                &:hover {

                    &:before {
                        opacity: 0;
                    }

                    // &:nth-child(1) {
                    //     background: transparent url('../img/home/TrainMed-Profil_farbig.jpg') left top no-repeat;
                    //     background-size: cover;
                    //     transition-delay: 1s;
                    //     transition: all 1s ease-in-out;
                    // }

                    // &:nth-child(2) {
                    //     background: transparent url('../img/home/TrainMed-Angebot_farbig.jpg') left top no-repeat;
                    //     background-size: cover;
                    //     transition-delay: 0.20s;
                    // }

                    // &:nth-child(3) {
                    //     background: transparent url('../img/home/TrainMed-Service_farbig.jpg') left top no-repeat;
                    //     background-size: cover;
                    //     transition-delay: 0.20s;
                    // }

                    // &:nth-child(4) {
                    //     background: transparent url('../img/home/TrainMed-Profil_farbig.jpg') left top no-repeat;
                    //     background-size: cover;
                    //     transition-delay: 0.20s;
                    // }

                    figcaption {

                        &:before {
                            opacity: 1;
                            transform: translate3d(0, 0, 0);
                        }

                        p {
                            opacity: 1;
                            transform: translate3d(0, 0, 0);
                            transition-delay: 0.3s;
                        }

                        h2 {
                            opacity: 1;
                            transform: translate3d(0, 40%, 0);

                        }
                    }
                }
            }
        }
    }
    .informations {
        margin-bottom: 50px;
        lost-utility: clearfix;

        header {
            lost-center: $wrapper-max-width $wrapper-padding flex;

            h2 {
                margin: 0;
                text-align: center;
                width: 100%;
            }
        }

        .slider {
            lost-center: $wrapper-max-width $wrapper-padding;

            .bx-wrapper {
                box-shadow: none;
                border: none;
                margin-bottom: 20px;

                .bx-viewport {

                    ul.FactSlider {
                        margin: 0;
                        padding: 0;
                        text-align: center;


                        li {
                            cursor: pointer;

                            h3 {
                                font-size: 1.125rem;
                                font-weight: 300;
                                width: 80%;
                                margin-left: auto;
                                margin-right: auto;

                                span {
                                    font-weight: 400;
                                }
                            }
                        }

                    }
                }

                .bx-controls {
                    .bx-pager {
                        padding-top: 0;

                        &.bx-default-pager {
                            bottom: -15px;

                            a {
                                background: $grey-page-title-text;
                                width: 8px;
                                height: 8px;

                                &:hover,
                                &:focus,
                                &:active,
                                &.active {
                                    background: $blue;
                                }
                            }
                        }

                    }
                }

            }
        }

    }
}

// .ribbon {
//     line-height: .8em;
//     font-size: 1.5em;
//     text-transform: uppercase;
//     text-align: center;
//     text-shadow: 1px 1px 5px rgba(0, 0, 0, .15);
//     letter-spacing: 0;
//     display: block;
//     width: 6rem;
//     height: 3rem;
//     background: $blue;
//     color: white;
//     padding-top: 1rem;
//     position: relative;
//     z-index: 3;
//     -webkit-filter: drop-shadow(0 .3rem .15em rgba(0, 0, 0, .5));
//     left: 50%;
//     transform: translateX(-50%);


//     &:after {
//         content: "";
//         width: 0;
//         height: 0;
//         border-right: 3rem solid transparent;
//         border-left: 3rem solid transparent;
//         border-top: 1.5rem solid $blue;
//         position: absolute;
//         top: 3rem;
//         left: 0;
//     }

// }