@import "font-awesome";
.main {
	.login {
		height: 150px;
	}
	.service {
		lost-center: $wrapper-max-width $wrapper-padding flex;
		margin-bottom: 40px;
		margin-top: 30px;

		article {
			lost-utility: clearfix;
			width: 100%;
			margin-bottom: 15px;
			header {
				h3 {
					font-size: 1.313em;

					&.list-dash {
						margin-left: 20px;

						&:before { 
							margin-left: -10px;
						}
					}
				}
			}

			h4 {
				&.subServiceCategory {
					font-weight: 400; 
					margin-left: 12px;
					width: 100%;
				} 
			}
			
			&.member {
				header {
					h3:after {
						@extend .material-icons;
						content:'\e0da';
						margin-left: 10px;
						vertical-align: sub;
					}
				}
				.tip {
					color: $grey-page-title-text;
					font-size: .875em;
				}
			}
			
			.row {
				display: flex;
				flex-wrap: wrap; 
				
				a:link,
				a:visited,
				span {
					border: 1px solid $grey-page-title-text;
					color: $grey-page-title-headline;
					font-size: .875rem;
					line-height: 1.2;
					padding: 8px 40px;
					margin: 10px;
					text-decoration: none;
					position: relative;
					transition: all .3s ease-in-out;

					flex: 1 1 calc(100% - 20px);
					display: flex;
    				align-items: center;


					@include mq($from: phablet) {
						flex: 0 1 calc(50% - 20px);
					}

					&.file {
						&:before{
							content: "\f016"; 
							font-family: FontAwesome;
							font-size: 1.5rem; 
							vertical-align: sub;
    						padding-right: 10px;
    						margin-left: -30px;
    					}
					}

					&.word {
						&:before{
							content: "\f1c2"; 
							font-family: FontAwesome;
							font-size: 1.5rem; 
							vertical-align: sub;
					    	padding-right: 10px;
					    	margin-left: -30px;
					    }
					}
					&.pdf {
						&:before{
							content: "\f1c1"; 
							font-family: FontAwesome;
							font-size: 1.5rem; 
							vertical-align: sub;
    						padding-right: 10px;
    						margin-left: -30px;
    					}
					}

					&.download {
						&:after {
							@extend .material-icons;
							content:'\e2c4';
							color: $grey-page-title-text;
							position: absolute;
							right: 10px;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					&.external-link {
						&:after {
							@extend .material-icons;
							content:'\e895';
							position: absolute;
							right: 10px;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					&.encrypted {
						&:after {
							@extend .material-icons;
							content:'\e0da';
							color: $grey-page-title-text;
							position: absolute;
							right: 10px;
							top: 50%;
							transform: translateY(-50%);
							width: 25px;
						}
					}
				}
				a:hover,
				a:focus,
				a:active {
					border: 1px solid $blue;
					color: $blue;
				}
				span {
					padding-left: 8px;
					min-height: 46px;
				}
			}			
		}
	}
}