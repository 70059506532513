body.layered {
    overflow-y: hidden;
}

.homepage-hero-module {
    border-right: none;
    border-left: none;
    position: relative;
}

.no-video .video-container video,
.touch .video-container video {
    display: none !important;
}

.no-video .video-container .poster,
.touch .video-container .poster {
    display: block !important;
}

.video-container {
    position: relative;
    bottom: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    //background: #000 url('../img/trurnit-weihnachtsaktion-poster.jpg') no-repeat;
    background-size: cover;
    background-position: center center;

    @media only screen and (max-width: 768px) {
        //height: 600px;
    }

}

#hero-vid {
    position: relative;
    bottom: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #000;
    min-height: 100%;
    min-width: 100%;
    perspective: 1000;
    position: fixed;
    right: 0;
    width: auto;
    z-index: 0;

    @media only screen and (max-width: 768px) {
        //height: 600px;
        //width: auto;
    }
}

.video-container .poster img {
    width: 100%;
    bottom: 0;
    position: absolute;
}

.video-container .filter {
    z-index: 100;
    position: absolute;
    //background: rgba(0, 0, 0, 0.4);
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}

.video-container .title-container {
    z-index: 1000;
    position: absolute;
    bottom: 8%;
    width: 100%;
    text-align: center;
    color: #fff;
}

.video-container .description .inner {
    font-size: 1em;
    width: 45%;
    margin: 0 auto;
}

.video-container .link {
    position: absolute;
    bottom: 3em;
    width: 100%;
    text-align: center;
    z-index: 1001;
    font-size: 2em;
    color: #fff;
}

.video-container .link a {
    color: #fff;
}

/* .video-container video {
    position: absolute;
    z-index: 3;
    bottom: 0;
} */

.video-container video.fillWidth {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .9);
    top: 0;
    left: 0;
}



.video-play-button {
    position: relative;
    z-index: 10;
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    /* background: #fa183d; */
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, .5);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, .9);
    border-radius: 50%;
    -webkit-transition: all 200ms;
    transition: all 200ms;
}

.video-play-button:hover:after {
    background: rgba(255, 255, 255, 1);
}

.video-play-button img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
}

.video-play-button span {
    ddisplay: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 20px solid #a50034;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    left: -2px;
}

@-webkit-keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.25);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.25);
        opacity: 0;
    }
}

@keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.25);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.25);
        opacity: 0;
    }
}

.video-overlay-container {
    position: relative;
    bottom: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.video-overlay {
    position: fixed;
    z-index: -1;
    //top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .9);
    opacity: 0;
    transition: all ease-in-out 500ms;
    height: 100%;
    width: 100%;
}

.video-overlay.open {
    position: fixed;
    z-index: 1032;
    opacity: 1;
}

.video-overlay-close {
    position: absolute;
    z-index: 1000;
    top: 15px;
    right: 20px;
    font-size: 36px;
    line-height: 1;
    font-weight: 400;
    color: #FFF;
    text-decoration: none;
    cursor: pointer;
    transition: all ease-in-out 200ms;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #111;
    display: flex;
    justify-content: center;
    align-items: center;
    text-indent: -9999px;

    &:hover {

        &::before,
        &::after {
            background: $grey-nav;
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 50%;
        top: 50%;
        left: 13px;
        margin-top: -1px;
        background: #FFF;
        transition: background .3s ease-in-out;   
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

.video-overlay-close:hover {
    color: #fa183d;
    text-decoration: none;
}

.video-overlay iframe,
.video-overlay video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
    display: inline-block;
    z-index: 9999;
    max-width: 100%;
    width: 90%;
    height: 90%;
    max-width: 1080px;
    max-height: 720px;
}

.video-overlay video {
    height: auto;
}

.fillWidth {
    top: 50%;
    left: 50%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: relative;

    .layerVideo {
        position: relative;
        padding-bottom: 56.25%;
        /* Default for 1600x900 videos 16:9 ratio*/
        padding-top: 0px;
        height: 0;
        overflow: hidden;
    }
}


// @media only screen 
//   and (min-device-width: 320px) 
//   and (max-device-width: 480px)
//   {
//     video {
//         display: none;
//     }
// }