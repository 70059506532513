.main {
    .profil {
        lost-center: $wrapper-max-width $wrapper-padding flex;
        margin-bottom: 50px;
    }

    .profil-contact {
        margin-bottom: 50px;
        lost-utility: clearfix;


        article {
            lost-center: $wrapper-max-width $wrapper-padding flex;

            figure {
                @include mq($from: phablet) {
                    lost-align: center flex;
                    align-items: center;
                }

                img {
                    lost-column: 1/1;

                    @include mq($from: phablet) {
                        lost-column: 1/2;
                    }
                }

                figcaption {
                    lost-column: 1/1;
                    lost-utility: clearfix;

                    @include mq($from: phablet) {
                        lost-column: 1/2;
                        lost-utility: none;
                    }

                    h2 {
                        margin-bottom: 0;
                        margin-top: 1rem;

                        @include mq($from: phablet) {
                            margin-bottom: 7px;
                        }
                    }

                    .team-date {
                        margin-bottom: 7px;
                        lost-column: 1/1;
                        lost-utility: clearfix;

                        @include mq($from: mobile-xl, $until: phablet) {
                            lost-column: 1/2 0;
                        }

                        @include mq($from: phablet) {
                            lost-column: 1/1;
                            lost-utility: clearfix;
                        }

                        &:last-child {
                            margin-bottom: 30px;
                        }

                        h3 {
                            font-size: 1rem;
                            font-weight: 400;
                            margin-bottom: 10px;
                        }

                        p {
                            margin-bottom: 0;
                            margin-top: 5px;

                            &.phoneContact {
                                /* &:before {
									@extend .material-icons;
									content: 'phone';
									color: $grey-nav;
									font-size: 1rem;
									vertical-align: sub;
									margin-right: 5px;
								} */
                            }

                            &.emailContact {
                                :before {
                                    @extend .material-icons;
                                    content: '\e0be';
                                    color: $grey-nav;
                                    font-size: 1rem;
                                    vertical-align: sub;
                                    margin-right: 5px;
                                }
                            }
                        }

                        a:link,
                        a:visited {
                            font-size: .875rem;
                            text-decoration: none;

                            &.phoneLink {
                                color: #444;
                            }

                            &.emailLink {
                                border-bottom: 1px solid lighten($grey-page-title-text, 10%);
                                color: #444;
                                transition: all .3s ease-in-out;

                                &:before {
                                    display: none;
                                }
                            }
                        }

                        a:hover,
                        a:focus,
                        a:active {
                            &.emailLink {
                                border-bottom: 1px solid $blue;
                                color: $blue;
                            }
                        }
                    }
                }
            }
        }

    }

    .angebot-link {
        header {
            margin-bottom: 40px;
            lost-center: $wrapper-max-width $wrapper-padding;
            text-align: center;
            lost-utility: clearfix;

            a:link,
            a:visited {
                display: inline-block;
                margin: 0 auto;
                text-decoration: none;
                text-align: center;

                h2 {
                    color: $grey-page-title-text;
                    display: block;
                    font-size: 1.5rem;
                    margin: 0;
                    text-align: center;
                    transition: color .3s ease-in-out;

                    @include mq(860px) {
                        display: inline-block;
                    }
                }

                i {
                    overflow: hidden;
                    transition: background 0.3s, color 0.3s, box-shadow 0.3s;
                    position: relative;
                    top: 3px;
                    vertical-align: middle;

                    @include mq(860px) {
                        top: -3px;
                    }
                }

                i:before {
                    @extend .material-icons;
                    content: '\e315';
                    font-size: 5rem;
                    line-height: 0.7;
                    vertical-align: middle;
                    color: #ccc;
                    position: relative;

                    @include mq(860px) {
                        line-height: 1;
                    }
                }
            }

            a:hover,
            a:focus,
            a:active {
                h2 {
                    color: $blue;
                }

                i:before {
                    animation: toRightFromLeft 0.8s forwards;
                }

                @-webkit-keyframes toRightFromLeft {
                    49% {
                        -webkit-transform: translate(100%);
                    }

                    50% {
                        opacity: 0;
                        -webkit-transform: translate(-100%);
                    }

                    51% {
                        opacity: 1;
                    }
                }

                @-moz-keyframes toRightFromLeft {
                    49% {
                        -moz-transform: translate(100%);
                    }

                    50% {
                        opacity: 0;
                        -moz-transform: translate(-100%);
                    }

                    51% {
                        opacity: 1;
                    }
                }

                @keyframes toRightFromLeft {
                    49% {
                        transform: translate(100%);
                    }

                    50% {
                        opacity: 0;
                        transform: translate(-100%);
                    }

                    51% {
                        opacity: 1;
                    }
                }
            }
        }
    }
}