@mixin button-cta() {
    text-decoration: none;
    padding: 10px 2em;
    display: inline-block;
    margin: 1em 0;
    transition: all 0.8s, color 0.3s 0.3s;
    background: $blue;
    color: #fff;
    border: 1px solid $blue;

    &:hover {
        box-shadow: 300px 0 0 0 rgba(0, 0, 0, 0.25) inset;
    }

    .background-blue & {
        background: #FFF;
        color: $blue;

        &:hover {
            box-shadow: 300px 0 0 0 rgba($blue, 0.5) inset;
            color: #FFF;
        }
    }
}

.button-cta {
    @include button-cta();
}

@mixin button() {
    text-decoration: none;
    padding: 10px 2em;
    display: inline-block;
    margin: 1em 0;
    transition: all 0.8s, color 0.3s 0.3s;
    background: #FFF;
    color: $blue;
    border: 1px solid $blue;
    height: auto;

    &:hover {
        box-shadow: 300px 0 0 0 rgba($blue, 1) inset;
        color: #FFF;
    }
}

.button {
    @include button();
}