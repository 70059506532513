.nav-wrapper {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1000;
}

.navbar {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    height: 50px;
    overflow: hidden;
    transition: padding .2s ease-in-out, box-shadow .2s ease-in-out;

    @include mq($from: desktop) {
        grid-template-columns: 1fr;
        height: auto;
        padding-top: 20px;
    }

    &.fixed-nav {
        box-shadow: 0px -6px 6px 6px rgba(0, 0, 0, 0.2);
        padding-top: 0;
        z-index: 1001;
    }

    .logo {
        height: auto;
        width: auto;
        margin-left: 20px;

        @include mq($from: desktop) {
            display: none;
        }

        a {
            text-decoration: none;
        }

        .PageTitle-logoText {
            color: $blue;
            font-weight: 300;
            font-size: 1.25em;
            line-height: 1;
            span {
                font-weight: 400;
            }
        }

    }

    .nav {
        display: flex;
        flex-direction: column;
        position: fixed;
        justify-content: start;
        top: 50px;
        right: 0;
        background: #fff;
        width: 350px;
        height: calc(100vh - 55px);
        transform: translateX(450px);
        text-align: left;
        overflow: hidden;
        transition: transform .3s ease-in-out;

        @include mq($from: desktop) {
            // flex-direction: row;
            // align-items: center;
            display: grid;
            grid-template-columns: 3fr 1fr;
            justify-self: end;
            align-items: center;
            position: relative;
            top: 0;
            height: auto;
            text-align: left;
            overflow: visible;
            width: 100%;
            transform: translate(0);

            max-width: $wrapper-max-width;
            padding: $wrapper-padding;
            margin: 0 auto;
        }

    }

    ul {
        background-color: #fff;
        padding: 3em;

        @include mq($from: desktop) {
            padding: 0;
            display: flex;

        }

        li {
            display: block;
            padding: 1em 0;

            @include mq($from: desktop) {
                padding: 0;
            }

            a:link,
            a:visited {
                border-bottom: 2px solid lighten($grey-nav, 25%);
                color: $grey-nav;
                display: inline-block;
                font-size: 1.25em;
                margin-right: 25px;
                padding-top: 0;
                position: relative;
                text-decoration: none;
                overflow: hidden;
                height: 27px;

                @include mq($from: desktop) {
                    font-size: 1em;
                }
            }

            a.active {
                color: $blue;
                border-bottom: 2px solid $blue;
            }

            a:before {
                position: absolute;
                top: 0;
                left: 0;
                overflow: hidden;

                max-width: 0;
                border-bottom: 2px solid $blue;
                color: $blue;
                content: attr(data-hover);
                transition: max-width 0.5s;
            }

            a:hover:before {
                max-width: 100%;
            }

            &.nav-badge {
                display: flex;

                &:after {
                    content: "NEU";
                    box-sizing: border-box;
                    // border: 1px solid #2349a3;
                    // border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all .25s;
                    color: #2349a3;
                    margin-left: 0.25rem;
                    right: 0;
                    position: relative;
                    width: auto;
                    font-size: .875em;
                    top: -5px;
                    height: 24px;
                    font-weight: 500;
                    margin-right: 25px;
                }

                a:link,
                a:visited {
                    margin-right: 0;
                }
            }
        }

        &.ClientArea {
            padding-top: 0;
            justify-content: flex-end;

            li {
                .button {
                    @include button();
                    margin: 0;
                    font-size: .875em;

                    &.button-cta {
                        @include button-cta();
                        margin: 0;
                        font-size: .875em;
                    }

                    &.active {
                        cursor: default;
                        pointer-events: none;
                        background: #FFF;
                        color: $blue;

                        &:hover {
                            box-shadow: none;
                            color: $blue;
                        }
                    }

                }
            }
        }
    }
}

.mobile-nav {
    transform: translateX(0%) !important;
}

.menu-toggle {
    display: block;
    cursor: pointer;
    justify-self: end;
    margin-right: 25px;

    @include mq($from: desktop) {
        display: none;
    }

    .bar {
        display: block;
        cursor: pointer;
        width: 25px;
        height: 3px;
        background-color: #3f3f3f;
        margin: 5px auto;
        transition: all 0.3s ease-in-out;
    }
}

.menu-toggle:hover {
    cursor: pointer;
}

#mobile-menu.is-active .bar:nth-child(2) {
    opacity: 0;
}

#mobile-menu.is-active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

#mobile-menu.is-active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}